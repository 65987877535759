import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap'
import 'axios'
import 'vue-axios'
import 'aos/dist/aos.css'
import App from './App.vue'
import router from './services/router'
import store from './services/store'
import AOS from 'aos'
import VueScrollTo from 'vue-scrollto'

createApp(App).use(router).use(store).use(AOS).use(VueScrollTo).mount('#app');
